#kid-grid-header {
    padding-top:  10px;
    margin-left:  5%;
    max-width: 90%;
    background-color: white;
}

#button-group {
    display:flex;
    justify-content:center;    
}

#second-prompt {
    color: rgb(28, 111, 206);
}

#menu {
    margin-left: -20px;
    margin-right: -20px;
    max-width: 110%;
}

@media screen and (max-width: 600px) {
    #kid-grid-header {
        padding-top:  10px;
        margin-left:  -5%;
        margin-right:  -5%;
        max-width: 110%;
        background-color: white;
    }
}