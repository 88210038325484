#logo {
    margin: 20px 10px;
    max-width: 400px;
}

#title {
    display: inline-block;
    font-size: xx-large;
    padding: 0px 0px -20px 10px;
    color:darkblue;
}

#banner {
    background-image: url("https://casatree.blob.core.windows.net/media/Website banner xmas.png") !important;
    background-size: cover !important;
    background-attachment: fixed !important;
}

#terms {
    margin-top: 10px;
    margin-right: 10px;
    float: right;
}

@media screen and (max-width: 600px) {
    #logo {
        margin: 20px 0px 0px -20px;
        max-width: 320px;
    }
}