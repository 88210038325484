#header {
    text-align: center;
}

#tenantSelect {
    text-align: center;
    font-size: large;
    background-image: url('https://casatree.blob.core.windows.net/media/HappyKids.svg') !important;
    background-size: cover;
    background-repeat: no-repeat;
}

#emphasis {
    text-align: center;
    font-size: large;
    font-weight: 600;
    margin-left: 60px;
    margin-right: 60px; 
}

#rules {
    display: flex;
    text-anchor: start;
    text-align: left;
    font-size: medium;
    margin-left: 60px;
    margin-right: 60px;
}

#final {
    display: flex;
    text-align: center;
    font-size: medium;
    margin-left: 220px;
    margin-right: 180px; 
}

#guidelines {
    display: block;
    text-align: center;
    word-spacing: .4em;
    font-size: medium;
    margin-left: 60px;
    margin-right: 60px; 
}

#container {
    margin-bottom: 60px;
}

@media screen and (max-width: 600px) {
    #emphasis {
        text-align: center;
        font-size: large;
        font-weight: 600;
        margin-left: 0px;
        margin-right: 0px; 
    }
    #rules {
        display: flex;
        text-anchor: start;
        text-align: left;
        font-size: medium;
        margin-left: 0px;
        margin-right: 0px;
    }
    #final {
        display: flex;
        text-align: center;
        font-size: medium;
        margin-left: 0px;
        margin-right: 0px; 
    }
    #guidelines {
        display: block;
        text-align: center;
        word-spacing: .4em;
        font-size: medium;
        margin-left: 0px;
        margin-right: 0px; 
    }
    #container {
        margin-bottom: 800px;
    }
}