#logo {
    margin: 20px 10px;
    max-width: 400px;
}

#topbanner {
    color: white;
    margin: 20px 10px;
}

#title {
    font-size: xx-large;
    padding: 10px 10px 20px 10px;
    color: black;
}

#banner {
    background-image: url("https://casatree.blob.core.windows.net/media/Website banner xmas.png") !important;
    background-size: cover !important;
    background-attachment: fixed !important;
}

#terms {
    margin-top: 10px;
    margin-right: 10px;
    float: right;
    color: black;

}

@media screen and (max-width: 600px) {
    #logo {
        margin: 20px 0px 0px -20px;
        max-width: 320px;
    }
}