#kidcard {
    max-width: 290px;
    height:  680px;
    text-align: left;
}

#kidimage {
    max-width: 290px;
}

#header {
    text-align: left;
    font-size: medium;
    font-weight: 700;
}

#giftprompt {
    display: inline-block;
    padding-left: 10px;
    font-weight: 700;
}

#kidgift {
    float: right;
    font-size: small;
    padding-left: 30px;
}

#noteprompt {
    font-weight: 700;
}

#note {
    float: right;
    font-size: small;
}

#tag {
    text-align: center;
    margin-bottom: 1em;
    color: blue;
}
